import { useDispatch, useSelector } from 'react-redux';
import { setShowTransferChat } from '@states/slices/chatbotSlice';
import { BiTransfer } from 'react-icons/bi';
import conversationService from '@/services/conversationService';
import { RootState } from '@/states/store';

function TransferButton() {
  const dispatch = useDispatch();
  const { currentConversation } = useSelector(
    (state: RootState) => state.chatbot
  );

  async function handleTransferChat() {
    dispatch(setShowTransferChat(true));
    const { fieldsUpdated } = await conversationService.updateConversation(
      currentConversation,
      { bot_success: true }
    );
    console.info('Updated conversation: ', fieldsUpdated);
  }

  return (
    <div
      className='transfer-button'
      role='button'
      aria-label='Transfer button'
      tabIndex={0}
      onClick={handleTransferChat}
    >
      <BiTransfer />
    </div>
  );
}

export default TransferButton;
