import { Feedback, MessageRating } from '@utils/types';
import apiClient from '@utils/axios';
import { BACKEND_URL } from '@utils/constants';

const conversationService = {
  async getChatHistory(user_id: string) {
    try {
      const response = await apiClient.get(
        `${BACKEND_URL}/conversations/${user_id}/history`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateConversation(
    conversation_id: string,
    data: { [key: string]: any }
  ) {
    try {
      const response = await apiClient.put(
        `${BACKEND_URL}/conversations/${conversation_id}`,
        data
      );
      const {
        fields_updated: fieldsUpdated,
        updated_conversation: updatedConversation,
      } = response.data;

      return { fieldsUpdated, updatedConversation };
    } catch (error) {
      throw error;
    }
  },
  async getConversationById(conversation_id: string) {
    try {
      const response = await apiClient.get(
        `${BACKEND_URL}/conversations/${conversation_id}/data`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async saveFeedback(feedback: Feedback, conversationId: string) {
    try {
      const response = await apiClient.post(
        `${BACKEND_URL}/conversations/${conversationId}/feedback`,
        {
          userFeedback: feedback.userFeedback,
          userRating: feedback.userRating,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async saveMessageRating(
    messageId: string,
    messageRating: MessageRating,
    _messageText: string,
    conversationId: string
  ) {
    try {
      const response = await apiClient.put(
        `${BACKEND_URL}/conversations/${conversationId}/message/${messageId}/feedback`,
        {
          rating: messageRating,
          ...(_messageText && { user_comment: _messageText }),
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async setConversationAsInactive(conversationId: string) {
    try {
      const response = await apiClient.post(
        `${BACKEND_URL}/conversations/${conversationId}/inactive`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async setConversationVariant(conversationId: string, variant: string) {
    try {
      const response = await apiClient.post(
        `${BACKEND_URL}/conversations/${conversationId}/variant`,
        {
          variant: variant,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default conversationService;
