import { useSelector } from 'react-redux';
import { RootState } from '@states/store';
import { useState } from 'react';
import { GoCopy, GoCheck } from 'react-icons/go';
import copy from 'copy-to-clipboard';
import './TransferModal.scss';

function TransferModal() {
  const { currentConversation } = useSelector(
    (state: RootState) => state.chatbot
  );
  const { currentLanguage } = useSelector((state: RootState) => state.settings);

  const [copied, setCopied] = useState(false);

  const adminLink = `${window.location.origin}/admin/chats?conversationId=${currentConversation}`;
  const conversationLink = `${window.location.origin}/conversation/${currentConversation}`;

  async function handleCopyLink(link: string) {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(link);
    } else {
      copy(link);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <div className='transfer-container'>
      <p className='transfer-text'>{currentLanguage.transfer.description}</p>
      <div className='transfer-link-container'>
        <p className='transfer-link'>{adminLink}</p>
        <button
          onClick={() => handleCopyLink(adminLink)}
          className='clipboard-btn'
        >
          {copied ? <GoCheck /> : <GoCopy />}
        </button>
      </div>
      <p className='access-text'>{currentLanguage.conversation.access_history}</p>
      <a className='view-conversation-link' href={conversationLink} target="_blank" rel="noopener noreferrer">
        {currentLanguage.conversation.view}
      </a>
      {/* TODO uncomment the below code once we have the display chat page in the agent-frontend */}
      {/* <p className='transfer-text'>
        You can also access the chat summary by clicking the button below:
      </p>
      <div className='transfer-btn-container'>
        <a
          href={adminLink}
          target='_blank'
          rel='noopener noreferrer'
          className='transfer-btn'
        >
          View Conversation
        </a> 
       </div> */}
    </div>
  );
}

export default TransferModal;
