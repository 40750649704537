import { LocalisationData } from '@utils/types';

export const localisationData: LocalisationData = {
  english: {
    language: 'english',
    user_name: 'Leumi Bot',
    availability: 'Available 24/7',
    online_now: 'Online Now',
    message: 'Type here',
    good_response: 'Good response',
    bad_response: 'Bad response',
    limit_exceeded: 'Message has exceeded character limit',
    upload: {
      disabled: 'File upload is disabled at this stage of the conversation.',
      error: 'Error uploading file.',
      title: 'Upload File',
      button: 'Upload',
    },
    restart: {
      new_chat: 'Starting new chat',
      new_user: 'Restarting chat with new user',
    },
    password: {
      title: 'Password Checker',
      description: 'Pease authenticate yourself to start the chat',
      placeholder: 'Enter Password',
      button: 'Verify Password',
      response: {
        empty: 'Please enter a password',
        wrong: 'Incorrect password. Please try again',
        settings: 'Error fetching settings. Please try again',
        success: 'Password verified successfully',
      },
    },
    settings: {
      title: 'Settings Preset',
      description: 'Pease choose a preset to load',
      placeholder: 'Enter preset name',
      button: 'Load Preset',
      response: {
        empty: 'Please enter a preset name',
        default: 'Default preset loaded successfully',
        error: 'Error fetching settings. Please try again',
        success: 'Settings preset loaded successfully',
      },
    },
    conversation: {
      loading: 'Loading Conversation...',
      error_fetching: 'Error fetching conversation. Please try again',
      access_history: 'In the meantime, you can access the conversation history by clicking the button below',
      view: 'View Conversation'
    },
    feedback: {
      requestFeedback:
        'The chat has ended.\nWe would love to hear your feedback.',
      sendButton: 'Send',
      cancelButton: 'Cancel',
      success: 'Feedback is sent.',
      error: 'Failed to send feedback, Please try again.',
      ratingFeedback: 'Rating Feedback',
      provideFeedback: 'Please provide your feedback here...',
    },
    new_chat: {
      request: 'The chat has ended. \n Would you like to start a new one?',
      button: 'New chat',
    },
    transfer: {
      title: 'Transfer Chat',
      description:
        'This conversation will be available in the admin panel within minutes at the following link:',
    },
  },
  hebrew: {
    language: 'hebrew',
    user_name: 'הבוט של לאומי',
    availability: 'זמין 24/7',
    online_now: 'מחובר עכשיו',
    message: 'הקלידו כאן',
    good_response: 'תגובה טובה',
    bad_response: 'תגובה גרועה',
    limit_exceeded: 'ההודעה חרגה ממגבלת התווים',
    upload: {
      disabled: 'לא ניתן להעלות קבצים בשלב זה של השיחה',
      error: 'שגיאה בהעלאת הקובץ',
      title: 'העלאת קובץ',
      button: 'העלאה',
    },
    restart: {
      new_chat: 'שיחה מתחילה מחדש',
      new_user: 'שיחה מתחילה מחדש עם משתמש חדש',
    },
    password: {
      title: 'בדיקת ססמה',
      description: 'אנא הכניסו ססמה כדי להתחיל לדבר',
      placeholder: 'הכניסו ססמה',
      button: 'בדיקת ססמה',
      response: {
        empty: 'אנא הכניסו ססמה',
        wrong: 'ססמה שגויה. אנא נסו שנית',
        settings: 'שגיאה בטעינת הגדרות. אנא נסו שנית',
        success: 'ססמה אומתה בהצלחה',
      },
    },
    settings: {
      title: 'טעינת הגדרות',
      description: 'אנא בחרו הגדרות לטעינה',
      placeholder: 'הכניסו שם להגדרות',
      button: 'טעינת הגדרות',
      response: {
        empty: 'אנא הכניסו שם הגדרות',
        default: 'הגדרות ברירת המחדל נטענו בהצלחה',
        error: 'שגיאה בטעינת ההגדרות. אנא נסו שנית',
        success: 'הגדרות נטענו בהצלחה',
      },
    },
    conversation: {
      loading: "טוען שיחה...",
      error_fetching: "שגיאה בעת טעינת השיחה. אנא נסה שוב",
      access_history: "בינתיים, תוכל לגשת להיסטוריית השיחה על ידי לחיצה על הכפתור למטה",
      view: "צפה בשיחה"
    },
    feedback: {
      requestFeedback: 'השיחה הסתיימה.\n נשמח לשמוע ממך משוב.',
      sendButton: 'שלח',
      cancelButton: 'בטל',
      success: '.משוב נשלח',
      error: '.שליחת המשוב נכשלה, אנא נסה שוב',
      ratingFeedback: 'דירוג שיחה',
      provideFeedback: 'אנא הכנס את המשוב שלך כאן...',
    },
    new_chat: {
      request: 'השיחה הסתיימה.\n האם תרצה להתחיל שיחה חדשה?',
      button: 'שיחה חדשה',
    },
    transfer: {
      title: 'העברת שיחה לנציג בכיר',
      description:
        'השיחה תהיה זמינה בפאנל הניהול בעוד כמה דקות דרך הקישור הבא:',
    },
  },
  spanish: {
    language: 'spanish',
    user_name: 'Leumi Bot',
    availability: 'Disponible 24/7',
    online_now: 'En línea ahora',
    message: 'Escribe aquí',
    good_response: 'Buena respuesta',
    bad_response: 'Mala respuesta',
    limit_exceeded: 'El mensaje ha excedido el límite de caracteres',
    upload: {
      disabled:
        'La carga de archivos está deshabilitada en esta etapa de la conversación.',
      error: 'Error al cargar el archivo.',
      title: 'Subir archivo',
      button: 'Subir',
    },
    restart: {
      new_chat: 'Chat reiniciado',
      new_user: 'Reiniciando chat con nuevo usuario',
    },
    password: {
      title: 'Verificador de contraseña',
      description: 'Autentíquese para iniciar el chat',
      placeholder: 'Ingrese la contraseña',
      button: 'Verificar contraseña',
      response: {
        empty: 'Por favor ingrese una contraseña',
        wrong: 'Contraseña incorrecta. Inténtalo de nuevo',
        settings: 'Error al cargar la configuración. Inténtalo de nuevo',
        success: 'Contraseña verificada',
      },
    },
    settings: {
      title: 'Configuración predeterminada',
      description: 'Por favor, elige una configuración para cargar',
      placeholder: 'Ingresa el nombre de la configuración',
      button: 'Cargar configuración',
      response: {
        empty: 'Por favor, ingresa un nombre de configuración',
        default: 'Configuración por defecto cargada exitosamente',
        error:
          'Error al obtener la configuración. Por favor, inténtalo de nuevo',
        success: 'Configuración cargada exitosamente',
      },
    },
    conversation: {
      loading: "Cargando conversación...",
      error_fetching: "Error al cargar la conversación. Por favor, inténtalo de nuevo",
      access_history: "Mientras tanto, puedes acceder al historial de conversación haciendo clic en el botón de abajo",
      view: "Ver conversación"
    },
    feedback: {
      requestFeedback:
        'El chat ha terminado.\nNos encantaría escuchar tus comentarios.',
      sendButton: 'Enviar',
      cancelButton: 'Cancelar',
      success: 'Se envían comentarios.',
      error: 'No se pudo enviar comentarios, inténtelo de nuevo por favor.',
      ratingFeedback: 'Retroalimentación de calificación',
      provideFeedback: 'Por favor, proporcione su retroalimentación aquí...',
    },
    new_chat: {
      request: 'La conversación ha terminado. \nTe gustaría iniciar una nueva?',
      button: 'Nueva conversación',
    },
    transfer: {
      title: 'Transferir chat',
      description:
        'Esta conversación estará disponible en el panel de administración en minutos en el siguiente enlace:',
    },
  },
};
